import axios from "./index";

class DashboardApi {
  static clientTransaction = (data) => {
    return axios.get(`/clientTransaction`);
  };

  static balanceOverviewChart = (data) => {
    return axios.get(`/clientInvestments`);
  };

  static dashboardDetails = (data) => {
    return axios.get(`/dashboardDetails`);
  };

  static productsPdfLinks = (data) => {
    return axios.get(`/productPdfs`);
  };

  static productPerformance = (data) => {
    return axios.get(`/productPerformance`);
  };
}

export default DashboardApi;
