// Chakra imports
import {
  Text,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Link
} from "@chakra-ui/react";
// Assets
import { ExternalLinkIcon } from '@chakra-ui/icons'

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { React, useState, useEffect } from "react";
import ProfileApi from "api/profileApi";

const Documents = ({ }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState([]);

  let title = "Documents"
  let tableHeaders = [
    "Document Name",
    "Created By",
    "Updated By"];

  const fetchInfo = () => {
    ProfileApi.getDocuments().then((res) => setData(res.data.results))
  };

  useEffect(() => {
    fetchInfo();
  }, []);
  return (
    <Card p='16px'>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <TableContainer>
          <Table>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                {tableHeaders.map((header, index) => {
                  return (
                    <Th key={'header_key_' + index} color='gray.400'>
                      {header}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => {
                return (
                  <Tr key={"row_" + index}>
                    <Td>
                      <Link href={row.document_link} isExternal>
                        <Text fontSize="sm" color="blue.500">
                          {row.document_name}<ExternalLinkIcon mx='2px' />
                        </Text>
                      </Link>
                    </Td>
                    <Td>
                      <Text fontSize="sm">
                        {row.created_by}@{new Date(row.created_at).toLocaleString()}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm">
                        {row.updated_by}@{new Date(row.updated_at).toLocaleString()}
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card >
  );
};

export default Documents;
