import React from "react";
import { useAuth } from './auth-context/auth.context';
import { useHistory } from 'react-router-dom';
import { Route } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
  Button,
  ModalHeader,
  Stack
} from "@chakra-ui/react";
// Chakra Imports
import { AxiosInterceptor } from "api/index";

export const ProtectedRoute = ({ ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  let { user } = useAuth();

  function openKYCpage() {
    history.push("/auth/userKYC")
  }

  function checkKyc(event) {
    onOpen(true)
    event.preventDefault();
    event.stopPropagation();
  }

  (!user || !user.token || user.token === "") ? history.push("/auth/signin") : "";
  return (
    <div onClick={user ? user.kyc ? null : checkKyc : null}>
      <Route {...rest} />
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>KYC Alert</ModalHeader>
          <ModalBody>
            Please complete your KYC to proceed.
          </ModalBody>
          <ModalFooter>
            <Stack spacing={4} direction='row' align='center'>
              <Button colorScheme='blue' onClick={openKYCpage}>Proceed with KYC</Button>
              <Button variant='ghost' mr={3} onClick={onClose}>
                Close
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>);
};
