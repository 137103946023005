// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import React, { useState } from "react";
import AuthApi from "../../api/auth";
import { useAuth } from "../../auth-context/auth.context";

function SignUp() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [hidden, setHidden] = useState(false);
  const [disable, setDisable] = useState(false);

  const { user } = useAuth();

  const titleColor = useColorModeValue("blue.300", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setDisable(true);
    e.preventDefault();
    AuthApi.Register(formData)
      .then((response) => {
        if (response.data.success) {
          setHidden(true);
          return setError(response.data.msg);
        } else {
          setDisable(false);
          return setError(response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          setDisable(false);
          return setError(error.response.data.msg);
        }
        setDisable(false);
        return setError("There has been an error.");
      });
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      ></Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        {user && user.token ? (
          <Text
            fontSize="xl"
            color={textColor}
            fontWeight="bold"
            textAlign="center"
            mb="22px"
          >
            You are already signed in.
          </Text>
        ) : (
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Text
              hidden={hidden}
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
            >
              Sign Up
            </Text>
            <Text
              hidden={hidden}
              fontSize="lg"
              color="gray.400"
              fontWeight="bold"
              textAlign="center"
              mb="22px"
            >
              Please provide your details for user registration
            </Text>
            <FormControl>
              <FormLabel
                hidden={hidden}
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
              >
                Username
              </FormLabel>
              <Input
                hidden={hidden}
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="text"
                placeholder="Your full name"
                mb="24px"
                size="lg"
                name="username"
                onChange={handleChange}
              />
              <FormLabel
                hidden={hidden}
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
              >
                Email
              </FormLabel>
              <Input
                hidden={hidden}
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="email"
                placeholder="Your email address"
                mb="24px"
                size="lg"
                name="email"
                onChange={handleChange}
              />
              <FormLabel
                hidden={hidden}
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
              >
                First Name
              </FormLabel>
              <Input
                hidden={hidden}
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="email"
                placeholder="Your first name"
                mb="24px"
                size="lg"
                name="first_name"
                onChange={handleChange}
              />
              <FormLabel
                hidden={hidden}
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
              >
                Last Name
              </FormLabel>
              <Input
                hidden={hidden}
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="email"
                placeholder="Your last name"
                mb="24px"
                size="lg"
                name="last_name"
                onChange={handleChange}
              />
              <FormLabel
                hidden={hidden}
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
              >
                Password
              </FormLabel>
              <Input
                hidden={hidden}
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="password"
                placeholder="Your password"
                mb="24px"
                size="lg"
                name="password"
                onChange={handleChange}
              />
              <FormLabel
                hidden={hidden}
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
              >
                Confirm Password
              </FormLabel>
              <Input
                hidden={hidden}
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="password"
                placeholder="Confirm your password"
                mb="24px"
                size="lg"
                name="c_password"
                onChange={handleChange}
              />
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color="red" marginBottom="15px" fontWeight="medium">
                  {error}
                </Text>
              </Flex>
              <Button
                onClick={handleSubmit}
                type="submit"
                hidden={hidden}
                disabled={disable}
                bg="blue.300"
                fontSize="10px"
                color="white"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                _hover={{
                  bg: "blue.200",
                }}
                _active={{
                  bg: "blue.400",
                }}
              >
                SIGN UP
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Already have an account?
                <Link
                  color={titleColor}
                  ms="5px"
                  href="#/auth/signin"
                  fontWeight="bold"
                >
                  Sign In
                </Link>
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default SignUp;
