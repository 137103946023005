// Chakra imports
import {
  Flex,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
  Button,
  ModalHeader,
  Stack,
  Checkbox,
  ModalBody,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
// Assets
import React, { useState, useEffect } from "react";
import BuyProducts from "./components/BuyProducts";
import BuySellProducts from "./components/BuySellProducts";
import DepositWithdraw from "./components/DepositWithdraw";
import { useHistory } from "react-router-dom";
import PlaceOrdersApi from "api/placeOrdersApi";
import { useAuth } from "auth-context/auth.context";

function WithdrawDeposit() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userTermsAndConditions, setUserTermsAndConditions] = useState(true);
  const [termsAndConditionsLink1, setTermsAndConditionsLink1] = useState();
  const [termsAndConditionsLink2, setTermsAndConditionsLink2] = useState();
  const [termsAndConditionsLink3, setTermsAndConditionsLink3] = useState();
  const [termsAndConditionsLink4, setTermsAndConditionsLink4] = useState();

  let { user } = useAuth();

  const [isChecked, setIsChecked] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });

  const handleCheckboxChange = (event) => {
    setIsChecked({
      ...isChecked,
      [event.target.name]: event.target.checked,
    });
  };

  const isAllChecked = Object.values(isChecked).every(Boolean);

  const acceptedTermsAndConditions = () => {
    PlaceOrdersApi.postUserTermsAndConditions().then((res) => {
      if (res.data.status) {
        setUserTermsAndConditions(true);
      }
    });
  };

  const fetchInfo = () => {
    PlaceOrdersApi.getUserTermsAndConditions().then((res) => {
      if (res.data) {
        if (user.kyc) {
          setUserTermsAndConditions(res.data.acceptedTermsAndConditions);
        }
      }
    });
    PlaceOrdersApi.getConfigValue("T%26C_general_issuance").then((res) => {
      if (res.data) {
        setTermsAndConditionsLink1(res.data[0].prop_value);
      }
    });
    PlaceOrdersApi.getConfigValue("T%26C_AMC_supplement").then((res) => {
      if (res.data) {
        setTermsAndConditionsLink2(res.data[0].prop_value);
      }
    });
    PlaceOrdersApi.getConfigValue("T%26Cs_Tracker_Addendum").then((res) => {
      if (res.data) {
        setTermsAndConditionsLink3(res.data[0].prop_value);
      }
    });
    PlaceOrdersApi.getConfigValue("T%26Cs_Custody").then((res) => {
      if (res.data) {
        setTermsAndConditionsLink4(res.data[0].prop_value);
      }
    });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Tabs size="md" variant="enclosed">
          <TabList>
            <Tab>Deposit/Withdraw</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <DepositWithdraw></DepositWithdraw>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <Modal isOpen={!userTermsAndConditions} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Updated Terms and Conditions</ModalHeader>
          <ModalBody>
            <Flex>
              The terms and Conditions have been updated. In order to continue,
              you must agree with the new Terms and Conditions
            </Flex>
            <Flex>
              <Stack spacing={4}>
                <Checkbox
                  name="checkbox1"
                  isChecked={isChecked.checkbox1}
                  onChange={handleCheckboxChange}
                >
                  <Link href={termsAndConditionsLink1} isExternal>
                    Structured product terms and conditions{" "}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Checkbox>
                <Checkbox
                  name="checkbox2"
                  isChecked={isChecked.checkbox2}
                  onChange={handleCheckboxChange}
                >
                  <Link href={termsAndConditionsLink2} isExternal>
                    Terms and conditions supplement - AMC{" "}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Checkbox>
                <Checkbox
                  name="checkbox3"
                  isChecked={isChecked.checkbox3}
                  onChange={handleCheckboxChange}
                >
                  <Link href={termsAndConditionsLink3} isExternal>
                    Terms and conditions supplement - Tracker
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Checkbox>
                <Checkbox
                  name="checkbox4"
                  isChecked={isChecked.checkbox4}
                  onChange={handleCheckboxChange}
                >
                  <Link href={termsAndConditionsLink4} isExternal>
                    Terms and conditions cash & va custody{" "}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Checkbox>
              </Stack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing={4} align="center">
              <Button
                colorScheme="blue"
                variant="outline"
                mr={3}
                onClick={() => history.push("/admin/dashboard")}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                isDisabled={!isAllChecked}
                onClick={acceptedTermsAndConditions}
              >
                Submit
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default WithdrawDeposit;
