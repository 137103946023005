import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  useToast,
  Text,
  useColorModeValue,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
// Assets
import signInImage from "../../assets/img/signInImage.png";
import AuthApi from "../../api/auth";
import { useAuth } from "../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import EmailOtpModal from "components/2fa/EmailOtpModal";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

function SignIn() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [disableLoginButton, setDisableLoginButton] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const history = useHistory();
  const { user, setUser } = useAuth();
  // Chakra color mode
  const titleColor = useColorModeValue("blue.300", "blue.200");
  const textColor = useColorModeValue("gray.400", "white");
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const bgColor = useColorModeValue("white", "gray.700");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setDisableLoginButton(true);
    AuthApi.Login(formData)
      .then((response) => {
        if (response.data.success) {
          toast({
            title: "Login Successful",
            description: response.data.success,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          return setProfile(response);
        } else {
          toast({
            title: "Login Error",
            description: response.data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        setDisableLoginButton(false);
      })
      .catch((error) => {
        setDisableLoginButton(false);
        if (error.code == "ERR_NETWORK") {
          return toast({
            title: "Network Error",
            description:
              "Unable to reach server at the moment. Please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        if (error.response.data.msg) {
          return toast({
            title: "Login Error",
            description: error.response.data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (
          error.response.data.non_field_errors.includes("OTP_REQUIRED")
        ) {
          setShowOtpModal(true); // Show OTP modal
        }
      });
  };

  const handleOtpVerified = () => {
    setShowOtpModal(false); // Hide OTP modal
    handleSubmit(); // Recall the login API
  };

  const setProfile = (response) => {
    let user = { ...response.data.user };
    user.token = response.data.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    return history.push("/dashboard");
  };
  return (
    <Flex position="relative" mb="40px">
      {showOtpModal && (
        <EmailOtpModal
          isOpen={showOtpModal}
          onClose={() => setShowOtpModal(false)}
          username={formData.email}
          onOTPVerified={handleOtpVerified}
        />
      )}
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        {user && user.token ? (
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "50%", lg: "42%" }}
          >
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="48px"
              mt={{ md: "150px", lg: "80px" }}
            >
              <Heading color={titleColor} fontSize="32px" mb="10px">
                You are already signed in.
              </Heading>
            </Flex>
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ userSelect: "none" }}
            m={{ base: "30px", md: "30px", lg: "0px" }}
            w={{ base: "100%", md: "50%", lg: "50%" }}
          >
            <Flex direction="column" w="100%" p="48px">
              <Heading color={titleColor} fontSize="32px" mb="10px">
                Welcome Back
              </Heading>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Username
                </FormLabel>
                <Input
                  required="true"
                  id="username-field"
                  mb="24px"
                  type="text"
                  placeholder="Your username"
                  onChange={handleChange}
                  name="email"
                  value={formData?.email}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    required="true"
                    id="password-field"
                    type={show ? "text" : "password"}
                    placeholder="Your password"
                    onChange={handleChange}
                    name="password"
                    value={formData?.password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? (
                        <ViewIcon></ViewIcon>
                      ) : (
                        <ViewOffIcon></ViewOffIcon>
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  maxW="100%"
                  mt="0px"
                >
                  <Text color="red" marginTop="10px" fontWeight="medium">
                    {error}
                  </Text>
                </Flex>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  bg="blue.300"
                  w="100%"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "blue.200",
                  }}
                  isLoading={disableLoginButton}
                  disabled={disableLoginButton}
                >
                  SIGN IN
                </Button>
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  Don't have an account?
                  <Link
                    color={titleColor}
                    href="#/auth/signup"
                    ms="5px"
                    fontWeight="bold"
                  >
                    Sign Up
                  </Link>
                </Text>
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  Forgot your username or password?
                  <Link
                    color={titleColor}
                    href="#/auth/resetPassword"
                    ms="5px"
                    fontWeight="bold"
                  >
                    Click Here
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
        {/* <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box> */}
      </Flex>
    </Flex>
  );
}

export default SignIn;
