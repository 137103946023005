// Chakra imports
import {
  Flex,
  FormControl,
  Select,
  Text,
  useColorModeValue,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState, useEffect } from "react";
import placeOrdersApi from "api/placeOrdersApi";

const DepositWithdraw = ({}) => {
  const [useShowCognitoForm, setUseShowCognitoForm] = useState(true);
  const [formData, setFormData] = useState({});
  const [allowedCurrencies, setAllowedCurrencies] = useState([]);
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  const bgColor = useColorModeValue(
    "white",
    useShowCognitoForm === "true" ? "white" : "gray.700"
  );
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "transfer_amount" ? parseInt(value, 10) : value,
    });
  };

  const handleSubmit = (e) => {
    if (!formData.transfer_type) {
      return toast({
        title: "Error",
        description: "Select Tranfer Type",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (!formData.transfer_currency) {
      return toast({
        title: "Error",
        description: "Select Transfer currency",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (!formData.transfer_amount) {
      return toast({
        title: "Error",
        description: "Enter Amount",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setDisable(true);

    placeOrdersApi
      .postClientTransaction([formData])
      .then((response) => {
        setDisable(false);
        return toast({
          title: "Success",
          description: "Your request has been submitted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        setDisable(false);
        return toast({
          title: "Error",
          description: error.response.data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const callAPI = () => {
    placeOrdersApi.getConfigValue("show_cognito_form").then((res) => {
      if (res.data) {
        setUseShowCognitoForm(res.data[0].prop_value);
      }
    });

    placeOrdersApi.getAllowedCurrencies("client_dep").then((res) => {
      setAllowedCurrencies(res.data);
    });
  };

  useEffect(() => {
    callAPI();
  }, []);
  return useShowCognitoForm === "true" ? (
    <Card
      backgroundRepeat="no-repeat"
      background="cover"
      bgPosition="10%"
      p="16px"
      h={{ sm: "220px", xl: "100%" }}
      gridArea={{ md: "1 / 1 / 1 / 1", xl: "1 / 1 / 1 / 1" }}
    >
      <CardBody h="100%" w="100%">
        <Flex
          direction="row"
          background="transparent"
          borderRadius="15px"
          p="20px"
          height="600px"
          width="100%"
          display="flex"
          bg={bgColor}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.cognitoforms.com/f/97z4Fbt-pEa6FxuP7AH9eg/3"
          ></iframe>
          <script src="https://www.cognitoforms.com/f/iframe.js"></script>
        </Flex>
      </CardBody>
    </Card>
  ) : (
    <Card>
      <CardBody>
        <Flex
          direction="column"
          borderRadius="15px"
          p="20px"
          display="flex"
          bg={bgColor}
        >
          <Text fontSize="lg">Deposit/Withdraw</Text>
          <Text fontSize="md">
            Please complete the below instructions to contact Blue Rabbit
            Trading desk
          </Text>
          <br></br>
          <Text fontSize="lg">Order Details</Text>
          <br></br>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Transfer Type
            </FormLabel>
            <Select
              name="transfer_type"
              placeholder="Please Select"
              onChange={handleChange}
            >
              <option value="deposit">Deposit</option>
              <option value="withdrawal">Withdraw</option>
            </Select>
            <br></br>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Transfer Currency
            </FormLabel>
            <Select
              name="transfer_currency"
              placeholder="Please Select"
              onChange={handleChange}
            >
              {allowedCurrencies.map((row, index) => {
                return (
                  <option key={index} value={row.account_id}>
                    {row.currency_ticker}
                  </option>
                );
              })}
            </Select>
            <br></br>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Amount (USD Equivalent)
            </FormLabel>
            <Input
              type="number"
              min="1"
              step="1"
              name="transfer_amount"
              onChange={handleChange}
            />
            <br></br>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color="red" marginBottom="15px" fontWeight="medium">
                {error}
              </Text>
            </Flex>
            <Button
              onClick={handleSubmit}
              type="submit"
              disabled={disable}
              bg="blue.300"
              fontSize="10px"
              color="white"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              _hover={{
                bg: "blue.200",
              }}
              _active={{
                bg: "blue.400",
              }}
            >
              SUBMIT
            </Button>
          </FormControl>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default DepositWithdraw;
