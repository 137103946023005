import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import AuthApi from "../../api/auth";

function EmailOtpModal({ isOpen, onClose, username, onOTPVerified }) {
  const [otp, setOtp] = useState("");
  const toast = useToast();
  const [formData, setFormData] = useState({
    otp: "",
    username: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      username: username,
    });
  };

  const handleSubmitOTP = (e) => {
    e.preventDefault();
    AuthApi.verifyOTP(formData)
      .then((response) => {
        if (response.data.success) {
          toast({
            title: "OTP",
            description: response.data.success,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(() => {
            onOTPVerified();
          }, 1000);
        }
      })
      .catch((error) => {
        toast({
          title: "OTP",
          description: error.response.data.error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleResendOTP = async () => {
    AuthApi.resendOTP({ username: username })
      .then((response) => {
        if (response.data.success) {
          toast({
            title: "OTP",
            description: response.data.success,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          title: "OTP",
          description: error.response.data.error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCloseOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter EMAIL OTP</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Input
            id="OTP-field"
            borderRadius="15px"
            mb="24px"
            fontSize="sm"
            type="text"
            placeholder="Your EMAIL OTP"
            size="lg"
            onChange={handleChange}
            name="otp"
            value={formData?.otp}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmitOTP}>
            Submit OTP
          </Button>
          <Button variant="ghost" onClick={handleResendOTP}>
            Resend OTP
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EmailOtpModal;
