// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ClientTransactions from "./components/ClientTransactions";
import { useAuth } from "../../../auth-context/auth.context";

function Reports() {
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <ClientTransactions />
    </Flex>
  );
}

export default Reports;
