import Axios from "axios";
import { API_SERVER } from "../config/constant";

const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  credentials: "include",
  headers: { "Content-Type": "application/json" },
});

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem("user"));
    if (accessToken && config.headers) {
      config.headers.Authorization = accessToken.token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;
