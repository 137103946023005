// Chakra Icons
import { BellIcon, SearchIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Box,
  Button,
  Stack,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { MdLogout } from "react-icons/md";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

import { useAuth } from "../../auth-context/auth.context";
import AuthApi from "../../api/auth";
import { useHistory } from "react-router-dom";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const { user, setUser } = useAuth();
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();

  // Chakra Color Mode
  let mainBlue = useColorModeValue("blue.300", "blue.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.500", "gray.200");
  let sunIcon = useColorModeValue("gray.500", "gray.200");
  let moonIcon = useColorModeValue("gray.500", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();

  const handleLogout = () => {
    AuthApi.Logout(user);
    setTimeout(() => {
      setUser(null);
      localStorage.removeItem("user");
      return history.push("/auth/signin");
    }, 1000);
  };

  return (
    <Stack direction="row" spacing={1}>
      {/* <InputGroup
        cursor="pointer"
        bg={inputBg}
        borderRadius="15px"
        w={{
          sm: "128px",
          md: "200px",
        }}
        me={{ sm: "auto", md: "20px" }}
        _focus={{
          borderColor: { mainBlue },
        }}
        _active={{
          borderColor: { mainBlue },
        }}
      >
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
            ></IconButton>
          }
        />
        <Input
          fontSize="xs"
          py="11px"
          color={mainText}
          placeholder="Type here..."
          borderRadius="inherit"
        />
      </InputGroup> */}
      {user && user.token ? (
        <>
          <Button
            variant="link"
            display={{ base: "none", md: "flex", lg: "flex" }}
          >
            Hi, {user.username}
          </Button>
          <Button
            display={{ base: "none", md: "flex", lg: "flex" }}
            onClick={handleLogout}
            colorScheme="white"
            variant="ghost"
            rightIcon={<MdLogout />}
          >
            <Text>Logout</Text>
          </Button>
          <IconButton
            display={{ base: "flex", md: "none", lg: "none" }}
            onClick={handleLogout}
            colorScheme="white"
            variant="ghost"
            icon={<MdLogout />}
          ></IconButton>
        </>
      ) : (
        <>
          <Button colorScheme="white" to="/auth/signin" variant="link">
            <Text display={{ sm: "none", md: "flex" }}>Sign In</Text>
          </Button>
        </>
      )}
      <IconButton
        colorScheme="white"
        variant="ghost"
        onClick={toggleColorMode}
        icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
      ></IconButton>
      <Menu>
        <MenuButton
          colorScheme="white"
          as={IconButton}
          aria-label="Options"
          icon={<BellIcon />}
          variant="ghost"
        ></MenuButton>
        <MenuList p="16px 8px">
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent time="Now" boldInfo="We are working on this!" />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Stack>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
