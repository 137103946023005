// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import Header from "./components/Header";
import Documents from "./components/Documents";
import ProfileInformation from "./components/ProfileInformation";
import { useAuth } from "auth-context/auth.context";

function Profile() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "gray.200");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { user, setUser } = useAuth();

  return (
    <Flex direction='column'>
      <Header
        backgroundProfile={bgProfile}
        name={user.username}
        email={user.email}
      />
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }} gap='20px'>
        {/* <PlatformSettings
          title={"Platform Settings"}
          subtitle1={"ACCOUNT"}
          subtitle2={"APPLICATION"}
        /> */}
        <ProfileInformation
          title={"Profile Information"}
          name={`${user.firstname} ${user.lastname}`}
          mobile={user.mobno}
          email={user.email}
          location={user.location}
        />
        <Documents />
      </Grid>
    </Flex>
  );
}

export default Profile;
